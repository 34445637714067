import React, { useContext } from 'react'
import { Link } from 'gatsby'

import './styles.scss'

import { Context } from '../../Context'
import { hasRole } from '../Auth'

const Header = () => {
  const {
    isAuth,
    loggedUser,
    logout
  } = useContext(Context)

  let rightHeader = <div className='right-header' />
  let timeSession = <div className='time-session' />

  if (isAuth) {
    timeSession = (
      <div className='time-session'>
        <Link to='/profile/'>
          Perfil: {loggedUser ? `${loggedUser.name} ${loggedUser.lastname}` : ''}
        </Link>

        {/* admin (id=1) */}
        {!hasRole(1) ? null : (
          <Link
            to='/admin/'
            className='btnAdmin'
          >
            ADMIN
          </Link>
        )}
      </div>
    )

    // let timeText = `${loginTime} segundos`
    // if (loginTime > 3600) {
    //   timeText = `${Math.round(loginTime / 3600)} horas`
    // } else if (loginTime > 59) {
    //   timeText = `${Math.round(loginTime / 60)} minutos`
    // }

    rightHeader = (
      <div className='right-header'>
        {/* <div>
          {timeText} de login
        </div> */}

        <button onClick={logout}>
          Cerrar sesión
        </button>
      </div>
    )
  }

  return (
    <>
      <header className='header'>
        {timeSession}

        <div className='app-name'>
          <Link
            to='/dashboard/'
            style={{
              textDecoration: 'none'
            }}
          >
            {' '}
          </Link>
        </div>

        {rightHeader}
      </header>
    </>
  )
}

export default Header
